import { Injectable} from '@angular/core';
import { User } from '../models/user';
//import { ApiService } from './api.service';
import { HttpClient} from "@angular/common/http";
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { ResultLogin, ResultLoginStatus } from './../models/resultLogin';
import { Credentials } from '../models/credentials';
import { jwtDecode } from "jwt-decode";
import { Base64, cifrarXor } from '../shared/helpers/utils';
import * as moment from 'moment';
moment.locale('es')
import { formatDate } from '@angular/common';




@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: User
  cred: Credentials;


  get resultLogin(): Observable<ResultLogin> {
    return this.resultLogin$.asObservable();
  };

  get userLogin(): Observable<User>{
    return this.userLogin$.asObservable();
  }

  private resultLogin$ = new BehaviorSubject<ResultLogin>(new ResultLogin());
  private userLogin$ = new BehaviorSubject<User>(new User());


  // Observable (result of login)
  get token(): Observable<string> {
    return this.token$.asObservable();
  };
  private token$ = new BehaviorSubject<string>("");

  private headers$ = new Subject<any>()

  encryptSecretKey = "JA#AOO3O2G$8P2LK";

  
  private urlAPI="https://calendar-api.solucionait.es"

  constructor(private http:HttpClient) { 

  }

  postLogin(userName: any, password: any): Observable<any>{
    return this.http.post(this.urlAPI+'/auth/login',{ "userName": userName,
    "password": password}
    )
  }

  login(cred:Credentials){

    this.cred = cred;
    let result = new ResultLogin();
    let user = new User()
    result.status = ResultLoginStatus.Logging;
    this.resultLogin$.next(result);

    
    
    this.postLogin(cred.email, cred.password).subscribe(res=>{
      if(res){
        if (res.token.length>0)
        {
          this.saveToken(res.token,'')
         // console.log(sessionStorage.getItem('authToken'),'obtener datos del token')
          result.status = ResultLoginStatus.Logged;
          result.credentials = cred;
          user= res.user
          
          this.userLogin$.next(user);
          this.resultLogin$.next(result);
          
          
       /*  this.getUser()*/
      }
          else if(res.error){
            let result = new ResultLogin();
            result.status = ResultLoginStatus.Error;
            this.resultLogin$.next(result);
            this.removeToken();
 
        }

      }
      else if(res.error){
        return ''
      }
     
    },
    
    error => {
      let result = new ResultLogin();
            result.status = ResultLoginStatus.Error;
            this.resultLogin$.next(result);
            this.removeToken();
      ;
     })
  }
  
/**
     *  Encrypt a credential object
     * @param {Credentials} cred  The cred
     *
     * @return encrypted credentials
     */


  getUser(userName: any, password: any): User {
      this.postLogin(userName, password).subscribe(data=>{data; 
      this.user= User.transfromFromApi(data.user);


    })
    return this.user
  }

  saveToken(authToken: string, authTokenExtras: string) {
    sessionStorage.setItem("authToken", authToken);
    sessionStorage.setItem("authTokenExtras", authTokenExtras);
  }


/**
* Removes token for sessionStorage
* 
* @memberOf SoledadAuthenticationService
*/
  removeToken() {
    sessionStorage.removeItem("authToken");
    sessionStorage.removeItem("authTokenExtras");
  }


  getTokenExpired(token){
    const tokendecode= jwtDecode(token);
    //return new Date(tokendecode.exp *1000).toLocaleString()
    return new Date(tokendecode.exp *1000)

  }

  isTokenExpired(expiration: any) {
    let currentHour: any

    //currentHour=moment(formatDate('Mon Feb 21 2024 22:55:48 GMT+0100 (hora estándar de Europa central)','dd-MM-yyyy HH:mm:ss','es'),'DD/MM/YYYY HH:mm:ss')
    currentHour = moment(formatDate(new Date(),'dd-MM-yyyy HH:mm:ss','es'),'DD/MM/YYYY HH:mm:ss')

    expiration  = formatDate(expiration,'dd-MM-yyyy HH:mm:ss','es')
    expiration  = moment(expiration, 'DD/MM/YYYY HH:mm:ss')

    const diff  = expiration.diff(currentHour,'hours')

    if (currentHour>expiration || currentHour==expiration )
    { 
      return true
    }
    else{
      if (diff<3)
      { 
        return true
      }    
      else
      {
        return false
      }
      
    }

  }

  getCredentials(){
    var cred: Credentials
    //var cred={'email':'','password':''}
    if (localStorage.getItem("saveCred")) 
      cred = JSON.parse(cifrarXor(Base64.decode(decodeURIComponent(localStorage.getItem("saveCred"))), this.encryptSecretKey));
    return cred

  }

}

