
import { Component, OnInit,Input, EventEmitter, Output } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { GlobalVariables } from 'src/app/services/local/globalVariables.service';




@Component({
  selector: 'app-main-menu-header',
  templateUrl: './main-menu-header.component.html',
  styleUrls: ['./main-menu-header.component.css']
})


export class MainMenuHeaderComponent implements OnInit {

  @Input() public title: string
  @Input() public search: boolean
  @Output() searchMenuHeader: EventEmitter<any>= new EventEmitter()
 
  filterValue = ''
  imagepath:string=''
  
  constructor(private router:Router,
              private globalVariables: GlobalVariables) 
              { }
 

  ngOnInit(): void {
   // this.imagepath=localStorage.getItem("urlimage")
    this.imagepath=this.globalVariables.getUrlImage()
  
  }


  clickGoMain(event){
    event.preventDefault();
    this.router.navigate(['main']);
   }

  
  clickExit(event){
    event.preventDefault();
    localStorage.clear()
    this.router.navigate(['login']);
   }

 
  clickGoToSchedules(event){
    this.router.navigate(['panel']);
   }

  handleSearch(value) {
    this.filterValue = value
    this.globalVariables.setSearchText(this.filterValue)
    this.searchMenuHeader.emit(this.filterValue)
  }


  openAsideMenu(event){
    document.getElementById("sideNavigation").style.display="flex";
    document.getElementById("close").style.display = "flex";
    document.getElementById("backdrop").style.display = "flex";
   
  }

  closeNav(event){

    event.stopPropagation();
    event.preventDefault();
    document.getElementById("sideNavigation").style.display = "none";
    document.getElementById("close").style.display = "none";
    document.getElementById("backdrop").style.display = "none";
  
  }



}
