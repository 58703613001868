import { Component, OnInit, Output,EventEmitter, Input } from '@angular/core';
import { FormControl, FormBuilder } from '@angular/forms';
import{debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Subject} from 'rxjs';



@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})

export class SearchComponent implements OnInit {
 
  searchText=''
  search=new FormControl('')
  searchTerm$ = new Subject<string>();
  listWorkshops: Array<any>=[]

  /*@Output('search') searchEmitter= new EventEmitter<string>()*/
  @Output('search') searchEmitter= new EventEmitter<any>()
  @Input() listFiltered:any

  constructor(private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
   this.listWorkshops=this.listFiltered;
   this.filterList()
 
 /* this.search.valueChanges
   .pipe(
    debounceTime(300)
   )
   .subscribe(value=>this.searchEmitter.emit(value))*/
  
  }

  filterList(): void {
   // console.log(this.searchTerm$,'this.searchTerm$')
    this.searchTerm$
      .pipe(
        debounceTime(200),
        distinctUntilChanged()
      )
      .subscribe(term => {
        this.listWorkshops = this.listFiltered
        .filter(item => item.name.toLowerCase().indexOf(term.toLowerCase()) >= 0);
        this.searchEmitter.emit(this.listWorkshops)
      
      
    });
  }



 


}

 
  


