
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-date-picker-recurrence',
  templateUrl: './date-picker-recurrence.component.html',
  styleUrls: ['./date-picker-recurrence.component.css']
})
export class DatePickerRecurrenceComponent implements OnInit {
  @Output() public dateSelected= new EventEmitter<any>();
  @Output() public changeDate = new EventEmitter<any>();
  @Input() public dateFullCalendar: any
  week: any = [
    "lun.",
    "mar.",
    "mie.",
    "jue.",
    "vie.",
    "sáb.",
    "dom."
  ];

  monthSelect: any[];
  dateSelect: any;
  dateValue: any;
  valor: boolean;
  date= new Date;
  pipe =new DatePipe('ES');
  dayWithPipe= null;
  monthWithPipe = null;
  yearWithPipe= null;
  daySelected: any;
  day: any;

  constructor() { 
   
  }

  ngOnInit(): void {

    this.dayWithPipe= this.pipe.transform(Date.now(), 'd')
    this.monthWithPipe = this.pipe.transform(Date.now(),'MM')
    this.yearWithPipe = this.pipe.transform(Date.now(), 'yyyy')
    this.getDaysFromDate(this.dayWithPipe,this.monthWithPipe, this.yearWithPipe)
  }
  getDaysFromDate(day:any, month:any, year:any) {

    const startDate = moment.utc(`${year}/${month}/01`)
    const endDate = startDate.clone().endOf('month')

    //this.dateSelect = moment.utc(`${year}/${month}/${day}`);
    this.dateSelect = moment.utc(`${year}/${month}/01`);
    this.daySelected= this.dayWithPipe

  
    const diffDays = endDate.diff(startDate, 'days', true)
    const numberDays = Math.round(diffDays);

    const arrayDays = Object.keys([...Array(numberDays)]).map((a: any) => {
      a = parseInt(a) + 1;
      const dayObject = moment(`${year}-${month}-${a}`);
      return {
        name: dayObject.format("dddd"),
        value: a,
        indexWeek: dayObject.isoWeekday()
      };
    });

    this.monthSelect = arrayDays;
  
  }

  ngOnchanges(changes: SimpleChanges)
  {
    
  }

  changeMonth(flag) {
    if (flag < 0) {
      const prevDate = this.dateSelect.clone().subtract(1, "month");
      this.getDaysFromDate(this.daySelected,prevDate.format("MM"), prevDate.format("YYYY"));
    } else {
      
      const nextDate = this.dateSelect.clone().add(1, "month");
      this.getDaysFromDate(this.daySelected,nextDate.format("MM"), nextDate.format("YYYY"));
    }

  }

  clickDay(val) {
    if(!this.valor){
      this.valor=true;
    }
     else 
       {
         if (this.valor)
        {
          this.valor=false;
        }
       }

     if(val.value<10 && val.value !=10)
       {this.day='0'+val.value}
     else
       {this.day= val.value}
     
    const monthYear = this.dateSelect.format('YYYY-MM')
    const parse = `${monthYear}-${this.day}`
    const objectDate = moment(parse)
    this.dateValue = objectDate;
    this.dateValue = objectDate;
    this.dateSelected.emit(parse);
    this.changeDate.emit(this.valor)


  }


}
