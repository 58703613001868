export class User{
    
        public idUser: Number;
        public userName: string;
        public firstName: string;
        public lastName: string;
        public email: string;
        public role: string
    
      
        static transfromFromApi(apiData: Object){

            let user= new User()

            user.idUser=apiData["idUser"]? apiData["idUser"]:"";
            user.userName= apiData["userName"]? apiData["userName"]:"";
            user.firstName= apiData["firstName"]? apiData["firstName"]:"";
            user.lastName= apiData["lastName"]? apiData["lastName"]:"";
            user.email= apiData["email"]? apiData["email"]:"";
            user.role= apiData["role"]? apiData["role"]:"";
       
            return user;
       
        }

        getUser():User {
            let user:User
          return user
        }



    
}

export class newUser{
    public  idUser: Number=null;
    public userName: string="";
    public firstName: string="";
    public lastName: string="";
    public email: string="";
    public role: string=""

}

