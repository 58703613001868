
import { Component, OnInit, Output, Input, EventEmitter, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/es';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-calendar-picker',
  templateUrl: './calendar-picker.component.html',
  styleUrls: ['./calendar-picker.component.css']
})
export class CalendarPickerComponent implements OnInit {
  @Output() public dateSelected= new EventEmitter<any>();
  @Output() public changeDate = new EventEmitter<any>();
  @Input() private dateFullCalendar: any;

  week: any = [
    "L",
    "M",
    "M",
    "J",
    "V",
    "S",
    "D"
  ];

  monthSelect: any[];
  dateSelect: any;
  dateValue: any;
  valor: boolean;
  date= new Date;
  pipe =new DatePipe('ES');
  dayWithPipe= null;
  monthWithPipe = null;
  yearWithPipe= null;
  daySelected: any;
  day: any;
  setDay:boolean=false;
  valSel:any;
  today= new Date;
  monthCurrent = new Date;
  monthChanged  = new Date;

  constructor() { 
   
  }

  ngOnInit(): void {

    // console.log(this.dateFullCalendar,'datefullcalendar')
    
    this.dayWithPipe= this.pipe.transform(Date.now(), 'd')
    this.monthWithPipe = this.pipe.transform(Date.now(),'MM')
    this.yearWithPipe = this.pipe.transform(Date.now(), 'yyyy')
    this.today= this.dayWithPipe
    this.monthCurrent= this.monthWithPipe
    this.getDaysFromDate(this.dayWithPipe,this.monthWithPipe, this.yearWithPipe)
    
}

ngOnChanges(changes: SimpleChanges){
 
  //console.log(this.dateFullCalendar,'datefullcalendar  en ngOnChanges')
  this.dayWithPipe= this.pipe.transform(this.dateFullCalendar, 'd')
  this.monthWithPipe = this.pipe.transform(this.dateFullCalendar,'MM')
  this.yearWithPipe = this.pipe.transform(this.dateFullCalendar, 'yyyy')
  
  this.getDaysFromDate(this.dayWithPipe,this.monthWithPipe,this.yearWithPipe)
  this.valSel=this.daySelected
  this.setDay=true

}


  
  getDaysFromDate(day:any, month:any, year:any) {
    const startDate = moment.utc(`${year}/${month}/01`)
    const endDate = startDate.clone().endOf('month')
  //  this.dateSelect = moment.utc(`${year}/${month}/${day}`);
    this.dateSelect = moment.utc(`${year}/${month}/01`);
    this.daySelected= day
    this.monthChanged=month
    
    const diffDays = endDate.diff(startDate, 'days', true)
    const numberDays = Math.round(diffDays);

    const arrayDays = Object.keys([...Array(numberDays)]).map((a: any) => {
      a = parseInt(a) + 1;
      const dayObject = moment(`${year}-${month}-${a}`);
      return {
        name: dayObject.format("dddd"),
        value: a,
        indexWeek: dayObject.isoWeekday()
      };
    });

    this.monthSelect = arrayDays;
   

  }

  changeMonth(flag) {
    if (flag < 0) {
      const prevDate = this.dateSelect.clone().subtract(1, "month");
      this.getDaysFromDate(this.daySelected,prevDate.format("MM"), prevDate.format("YYYY"));
    } else {
      const nextDate = this.dateSelect.clone().add(1, "month");
      this.getDaysFromDate(this.daySelected,nextDate.format("MM"), nextDate.format("YYYY"));
    }
  }

  clickDay(val) {

    this.setDay=true
    this.valSel=val.value
    
    if(!this.valor){
      this.valor=true;
    }
     else 
       {
         if (this.valor)
        {
          this.valor=false;
        }
       }
     
    
    if(val.value<10 && val.value !=10)
      {this.day='0'+val.value}
    else
      {this.day= val.value}

    const monthYear = this.dateSelect.format('YYYY-MM')
    const parse = `${monthYear}-${this.day}`
    const objectDate = moment(parse)
    this.dateValue = objectDate;
    this.dateSelected.emit(parse);
    this.changeDate.emit(this.valor)


  }


}
