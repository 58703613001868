export class Credentials {
    email: string;
    password: string;

    static newFromJSON(json: string): Credentials {
        let credentials: Credentials = null;
        try {
            credentials = new Credentials(JSON.parse(json));
        } catch (e) {
            console.error('Invalid credentials JSON.', e);
        }
        return credentials;
    }

    constructor(obj: Object = {}) {
        Object.assign(this, obj);
    }
}
