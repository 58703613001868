
import { Component, OnInit, Output,EventEmitter, ElementRef, ViewChild,Input, SimpleChanges } from '@angular/core';
import { Recurrence } from 'src/app/models/recurrence';
import { GlobalVariables } from 'src/app/services/local/globalVariables.service';
import { transfromStringtoDate,transfromDateEN, getDayWeek, getDayNumber, getNumberWeekDay,transfromStringtoDateEN } from 'src/app/shared/utilities/utilities';
import {DatePipe} from '@angular/common';


@Component({
  selector: 'app-recurrence-events',
  templateUrl: './recurrence-events.component.html',
  styleUrls: ['./recurrence-events.component.css']
})

export class RecurrenceEventsComponent implements OnInit {

 @Input() type:any;
 @Input() objParamsVar:any;
 @Output() objParams =new EventEmitter<any>();

 selected:any="forever";
 isActiveUntil: boolean=false;
 dateFrom: any='';
 dateTo: any='';
 dayWeek: number;
 lun:boolean; 
 mar:boolean;
 mie: boolean;
 jue:boolean;
 vie:boolean;
 sab:boolean;
 dom:boolean;
 dayNumber: number;
 arrDaysMonth: Array<any>;
 pos: number;
 dayName:string;
 monthName:string;
 montly: any="dayNumber";
 annually:any="dayNumberAnnually";
 count:number=1;
 every:number=1;
 repeatWeeklyMonday:boolean;
 repeatWeeklyTuesday:boolean;
 repeatWeeklyWednesday:boolean;
 repeatWeeklyThursday:boolean;
 repeatWeeklyFriday:boolean;
 repeatWeeklySaturday:boolean;
 repeatWeeklySunday:boolean;
 repeatWeekday:number;
 pipe =new DatePipe('ES');



 arrDaysWeek: Array<any>=[{'indexday':1,'value':'monday','checked':false,'name':'lun.'},
                          {'indexday':2,'value':'tuesday','checked':false,'name':'mar.'},
                          {'indexday':3,'value':'wednesday','checked':false,'name':'mié.'},
                          {'indexday':4,'value':'thursday','checked':false,'name':'jue.'},
                          {'indexday':5,'value':'friday','checked':false,'name':'vie.'},
                          {'indexday':6,'value':'saturday','checked':false,'name':'sáb.'},
                        ]

 
  constructor( private globalVariables: GlobalVariables ) { 
    
  }

  ngOnInit(): void {
    this.dateFrom=transfromStringtoDate(this.globalVariables.dateFromEvent)
    this.dateTo=transfromStringtoDate(this.globalVariables.dateFromEvent,true,5)
   
    if (this.objParamsVar.repeat)
    {
      this.type=this.objParamsVar.type?this.objParamsVar.type:this.type
      this.every=this.objParamsVar.repeatEvery
      this.count=this.objParamsVar.repeatDuring?this.objParamsVar.repeatDuring:this.count
      this.dateTo=this.objParamsVar.repeatUntil?transfromStringtoDate(this.objParamsVar.repeatUntil):this.dateTo

      if (this.objParamsVar.repeatAlways) this.selected='forever'
      if (this.objParamsVar.repeatUntil) this.selected='until'
      if (this.objParamsVar.repeatDuring) this.selected='count'

  
      switch (this.type){
        case "monthly":
          if (!this.objParamsVar.repeatWeekdayPosition)
                {
                  this.montly= 'dayNumber'
                }
          else {
            this.montly= 'positionDay'}
          break;
        case "annually":
          if (!this.objParamsVar.repeatWeekdayPosition)
                {
                  this.annually= 'dayNumberAnnually'
                }
          else this.annually= 'positionDayAnnually'
          break;
      }
     
    }
   /* else{
      if(this.type=='monthly' || this.type=='annually'){
        this.dayNumber=getDayNumber(this.globalVariables.dateFromEvent)
        this.arrDaysMonth=getNumberWeekDay(this.globalVariables.dateFromEvent)
        this.checkPositionDay(this.arrDaysMonth,this.dayNumber)
      }

    }*/

  }

  ngOnChanges(changes: SimpleChanges) {

      if(changes.type.currentValue=='monthly' || changes.type.currentValue=='annually'){
            this.dayNumber=getDayNumber(this.globalVariables.dateFromEvent)
            this.arrDaysMonth=getNumberWeekDay(this.globalVariables.dateFromEvent)
            this.checkPositionDay(this.arrDaysMonth,this.dayNumber)
      }
        
    
 
      if(!this.objParamsVar.repeatWeeklyMonday && !this.objParamsVar.repeatWeeklyTuesday && !this.objParamsVar.repeatWeeklyWednesday
        && !this.objParamsVar.repeatWeeklyThursday && !this.objParamsVar.repeatWeeklyFriday && !this.objParamsVar.repeatWeeklySaturday
        &&! this.objParamsVar.repeatWeeklySunday)
        {this.checkDayWeekSelect()}
      
    
        else if( this.objParamsVar.repeat==true)
        {
              if(this.objParamsVar.repeatWeeklyMonday ){
                this.arrDaysWeek[0].checked=true
                this.repeatWeeklyMonday=true
              }

              if(this.objParamsVar.repeatWeeklyTuesday){
                  this.arrDaysWeek[1].checked=true
                  this.repeatWeeklyTuesday=true
              }

              if(this.objParamsVar.repeatWeeklyWednesday){
                this.arrDaysWeek[2].checked=true
                this.repeatWeeklyWednesday=true
              }
            
              if(this.objParamsVar.repeatWeeklyThursday){
                this.arrDaysWeek[3].checked=true
                this.repeatWeeklyThursday=true
              }
          
              if(this.objParamsVar.repeatWeeklyFriday){
                this.arrDaysWeek[4].checked=true
                this.repeatWeeklyFriday=true
              }
            
              if(this.objParamsVar.repeatWeeklySaturday){
                this.arrDaysWeek[5].checked=true
                this.repeatWeeklySaturday=true
              }
            
              if(this.objParamsVar.repeatWeeklySunday){
                this.arrDaysWeek[6].checked=true
                this.repeatWeeklySunday=true
              }
        }
    


  }

  changeOptions(option:any){

  }

  changeCheckbox(event: Event, name: string, checked: boolean){
    this.setCheckedDayWeekRepeated(name,checked )
  }

  checkDayWeekSelect(){

      for(let i=0; i<this.arrDaysWeek.length; i++)
      {
        if(getDayWeek(this.globalVariables.dateFromEvent)==this.arrDaysWeek[i].indexday)
        {
          this.setCheckedDayWeekRepeated(this.arrDaysWeek[i].value,true)
          this.arrDaysWeek[i].checked=true
          i=this.arrDaysWeek.length
     
        }
        else{
          this.arrDaysWeek[i].checked=false
          this.setCheckedDayWeekRepeated(this.arrDaysWeek[i].value,false)
        }
      
      }
     
  }

  setCheckedDayWeekRepeated(name: string, checked: boolean){
    switch (name)
    {
      case 'monday':
        this.repeatWeeklyMonday=checked
      break;
      case 'tuesday':
        this.repeatWeeklyTuesday=checked
      break;
      case 'wednesday':
        this.repeatWeeklyWednesday=checked
      break;
      case 'thursday':
        this.repeatWeeklyThursday=checked
      break;
      case 'friday':
        this.repeatWeeklyFriday=checked
      break;
      case 'saturday':
        this.repeatWeeklySaturday=checked
      break;
      case 'sunday':
        this.repeatWeeklySunday=checked
      break;
      

    }
  }
  clearSetCheckedDayWeekRepeated(){
      this.repeatWeeklyMonday=false
      this.repeatWeeklyTuesday=false
      this.repeatWeeklyWednesday=false
      this.repeatWeeklyThursday=false
      this.repeatWeeklyFriday=false
      this.repeatWeeklySaturday=false
      this.repeatWeeklySunday=false
   
  }

  checkPositionDay(arr:Array<any>, day:any){
      for (let i=0; i<arr.length; i++)
      {
        if(arr[i].value==day)
        {
          this.pos=arr[i].posDay
          this.dayName= arr[i].name
          this.monthName=arr[i].monthName
        }
      }
  }


  clickDateSelected(event){
      this.dateTo= transfromStringtoDate(event)
      this.isActiveUntil=false
      //this.globalVariables.setdateToEvent(transfromDateEN(this.dateTo))
  }

  setActive(event){
    if(!this.isActiveUntil)
     this.isActiveUntil=true

    else
     this.isActiveUntil=false
    
     if (this.isActiveUntil) {
      var selecteduntil= document.getElementById('panel-selected')
      var selectpicker= document.getElementById('select-picker')
      var datepicker= document.getElementById('date-picker')
  
      if (selectpicker==null)  selectpicker= document.getElementById('select-picker')
      if (selecteduntil==null) selecteduntil= document.getElementById('panel-selected')
      if (datepicker==null) datepicker= document.getElementById('date-picker')
     
      selecteduntil.addEventListener('click', function(e) {
        e.preventDefault();
        e.stopPropagation();

       
        if(selectpicker!=null)
        {
          if (selectpicker.contains(e.target as Node)) {
            datepicker.style.display="flex"
      
          } else if(document.getElementById('next-date').contains(e.target as Node)) {
              if( datepicker!=null){
                document.getElementById('date-picker').style.display="block"
              }

          }
            else if(document.getElementById('prev-date').contains(e.target as Node)) {
              if( datepicker!=null){
               document.getElementById('date-picker').style.display="block"
              }
    
          }
          else if (!selectpicker.contains(e.target as Node))
          {
           
            datepicker.style.display="none"
     
          }
        }
      })
     }
      
  }

  saveRecurrence(){
      let objsParams=this.setObjectParams()
      this.objParams.emit(objsParams)

  }

  setObjectParams():Recurrence{
      let objsParams=new Recurrence()
      objsParams.type=this.type
      objsParams.repeat=true
      switch (this.selected)
      {
        case 'forever':
          objsParams.repeatAlways=true;
          break;
        case 'until':
          objsParams.repeatUntil= (transfromStringtoDateEN(transfromDateEN(this.dateTo)));
          objsParams.repeatDuring= 0
          break;
        case 'count':
          objsParams.repeatDuring=this.count
          break;
      }

      if(this.type=="monthly"){
        switch (this.montly)
          {
            case 'dayNumber':
              objsParams.repeatMonthDayPosition=this.dayNumber;
              break;
            case 'positionDay':
              objsParams.repeatWeekdayPosition=this.pos
              break;
          
          }
      }
     
     if(this.type=="annually"){
      switch (this.annually)
        {
          case 'dayNumberAnnually':
            objsParams.repeatMonthDayPosition=this.dayNumber;
            break;
          case 'positionDayAnnually':
            objsParams.repeatWeekdayPosition=this.pos
            break;
        }
     }
     

      switch (this.dayName)
      {
        case 'lunes':
          objsParams.repeatWeekday=1;
          break;
        case 'martes':
          objsParams.repeatWeekday=2;
          break;
        case 'miércoles':
          objsParams.repeatWeekday=3;
          break;
        case 'jueves':
          objsParams.repeatWeekday=4;
          break;
        case 'viernes':
          objsParams.repeatWeekday=5;
          break;
        case 'sábado':
          objsParams.repeatWeekday=6;
          break;
        case 'domingo':
          objsParams.repeatWeekday=7;
          break;
        default:
          objsParams.repeatWeekday=0;
        break     
      }

      if(this.type!='weekly') this.clearSetCheckedDayWeekRepeated()
    
      objsParams.type=this.type;
      objsParams.repeatEvery= this.every;
      objsParams.repeatWeeklyMonday= this.repeatWeeklyMonday;
      objsParams.repeatWeeklyTuesday=this.repeatWeeklyTuesday;
      objsParams.repeatWeeklyWednesday= this.repeatWeeklyWednesday;
      objsParams.repeatWeeklyThursday=this.repeatWeeklyThursday;
      objsParams.repeatWeeklyFriday= this.repeatWeeklyFriday;
      objsParams.repeatWeeklySaturday= this.repeatWeeklySaturday;
      objsParams.repeatWeeklySunday= this.repeatWeeklySunday;
      return objsParams

  }
           
}





