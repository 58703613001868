import { Component, OnInit, Input, SimpleChanges} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GlobalVariables } from 'src/app/services/local/globalVariables.service';


@Component({
  selector: 'app-aside-gral-menu-mobile',
  templateUrl: './aside-gral-menu-mobile.component.html',
  styleUrls: ['./aside-gral-menu-mobile.component.css']
})
export class AsideGralMenuMobileComponent implements OnInit {
  @Input() name: any;
  @Input() calendars: Array<any>
  id: any=0;
  imagepath:string=''

 
  constructor( private router:Router, private globalVariables: GlobalVariables) { }

  ngOnInit(): void {

    //this.imagepath=localStorage.getItem("urlimage")
    this.imagepath=this.globalVariables.getUrlImage()
    this.id= this.globalVariables.getIdWorkPlace()
  }

  ngOnChanges(changes: SimpleChanges)
  {
     if (changes.name)
     {
      this.name=changes.name.currentValue
     }
     
  }

 
  toggleBtn(event){
   const btn:HTMLElement  = document.getElementById('menu-btn')
   const  menu: HTMLElement = document.getElementById('sidemenu')
 
    btn.addEventListener('click', e=>{
      e.preventDefault();
     menu.classList.toggle("menu-expanded");
     menu.classList.toggle("menu-collapsed")
    }, false)
  }

  goToCalendars(event){

    event.stopPropagation();
    event.preventDefault();

    this.id= this.globalVariables.getIdWorkPlace()
    this.router.navigate(['resources/' + this.id + '/' + this.name])
  }

  clickGoToSchedules(event){
    event.stopPropagation();
    event.preventDefault();
    var arrIdCalendar: Array<any>=[]
    var exists: boolean=false
    this.calendars.forEach((calendar, i) => {
  
      if(!exists)
      {
        arrIdCalendar.push(calendar.idCalendar)
      }

      for (let i=0; i<arrIdCalendar.length; i++)
      {
        if (arrIdCalendar[i]==calendar.idCalendar)
        {exists= true
        }
        else exists=false
      }
 
    })

    var queryParams= {arrIdCalendar:arrIdCalendar, name: this.name}
    this.router.navigate(['panel/' + this.id ], {queryParams})


  }

  clickExit(event){
    event.stopPropagation();
    event.preventDefault();
    document.getElementById('myDOMElement')
    localStorage.clear()
    this.router.navigate(['login']);
   }

   clickGoMain(event){
    event.stopPropagation();
    event.preventDefault();
    this.router.navigate(['main']);
   }

  

 

}
