import { Component, OnInit,Input, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet'
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.css']
})
export class BottomSheetComponent implements OnInit {
   undone: boolean
  constructor(private readonly _sanitizer: DomSanitizer,private bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) 
    public data: any={}
    ) { }

  ngOnInit(): void {
    //this.title = this.data.title;
    this.title= this._sanitizer.bypassSecurityTrustHtml(
      this.data.title
    );
    this.undone= this.data.undone

  }

  title:  SafeHtml;
  clearBar(): void {
    this.bottomSheetRef.dismiss({
      message: 'close'
    })
  }

  undoneActions() {
    this.bottomSheetRef.dismiss({
      message: 'undone',
      type: this.data.type_actions 
    });
  }



}
