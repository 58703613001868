<div id="panel-selected" class="react-tabs__tab-panel--selected">
    <p>
        <span >Repetir cada</span>
        <input class="settings__input number-input" type="number" value="1" min="1"  [(ngModel)]="every">
        <span *ngIf="type=='daily'">día empezando el {{this.dateFrom}}</span>
        <span *ngIf="type=='weekly'">semana empezando el {{this.dateFrom}}  el:</span>
        <span *ngIf="type=='monthly'">mes empezando el {{this.dateFrom}}</span>
        <span *ngIf="type=='annually'">año empezando el {{this.dateFrom}}</span>
  
    </p>
    

   <div *ngIf="type=='weekly'" class="flex-box">
    
        <div *ngFor="let item of arrDaysWeek " class="checkday">
            <input type="checkbox" value ={{item.checked}} name={{item.value}} [(ngModel)]="item.checked" (change)="changeCheckbox($event,item.value, item.checked)">
            <label for="item.value" class="week"  >{{item.name}} </label>
        </div>

    </div>

    <div id="monthly" *ngIf="type=='monthly'">
        <span class="week">el </span>
        <select id="sel-day-opt" class="settings__input" [(ngModel)]="montly">
                <option value="dayNumber">{{dayNumber}} º día</option>
                <option value="positionDay">{{pos}} º {{dayName}}</option>
        </select>
        <span *ngIf="type=='monthly'"> de cada mes</span>
    </div>
    <div id="annually" *ngIf="type=='annually'">
        <select id="sel-year-opt" class="settings__input annually" [(ngModel)]="annually">
            <option value="dayNumberAnnually">{{dayNumber}} º día {{monthName}}</option>
            <option value="positionDayAnnually">{{pos}} º {{dayName}} de {{monthName}}</option>
        </select>
        <span *ngIf="type=='annually'"> de cada año</span>
    </div>  
    <div id="recurrence-types" class="content-height" >
        <span style="margin-right:5px">y continúa</span>
        <select id="sel-year-type-opt" class="settings__input annually" [(ngModel)]='selected'>
            <option value="forever">para siempre</option>
            <option value="count">durante</option>
            <option value="until">hasta</option>
        </select>
        <input *ngIf="selected=='count'" class="settings__input number-input settings__input_during" type="number" value="1" min="1" [(ngModel)]="count">
        <span *ngIf="selected=='count'"> veces</span>
        <div id="selected-until" *ngIf="selected=='until'" class="datepicker-recurrence">
           <input id="select-picker" (click)="setActive($event)" class="input flatpickr-input" placeholder= {{this.dateTo}} tabindex="2" type="text">
           <div style="display:none" id="date-picker" class="active-recurrence" >
             <app-date-picker-recurrence (dateSelected)="clickDateSelected($event)"></app-date-picker-recurrence>
            </div>
        </div>
        <div>
            <a class="button finished" (click)="saveRecurrence()">Finalizado</a>
        </div>
    </div>
</div>