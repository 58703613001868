import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { User } from '../models/user';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user: User

  constructor(private api: ApiService ) {}

  get activeUser(): Observable<User> {
    return this.activeUser$.asObservable();
   };
  
   private activeUser$ = new BehaviorSubject<User>(null);
  
   getData(user_id: string = null, userName: any, password: any) {

    // Params
    let params = [];
    if (user_id !== null) params['CLIENTE'] = user_id;

    this.api.postLogin(userName, password).subscribe(
        res => {
            try {
                if (!res.value || !res.value.Rows || res.value.Rows.length === 0) throw ("Error to obtain data user");
                this.user = User.transfromFromApi(res.value.Rows[0]);
               
            } catch (msg) {
                this.activeUser$.next(null);
            }
        }
    );
}
}
