<!DOCTYPE html>
<html>
  <section class="desktop calendar-header">
    <div class="logo common-margins-logo" (click)="clickGoMain($event)"> 
      <div>
        <img class="logo-image" src="assets/custom/{{imagepath}}/icono/logo.svg"/>
      </div>
      <div class="common-margins-title" >
        <span class="title-logo">1Cal</span>
      </div>
    </div>
    <div class="header-rest">
      <h1 class="title">
        {{name}}
      </h1>
    </div>
    <div class="header-rest">
      <button class="button button-centre" (click)="clickGoMain($event)">
         Centros
      </button>
    </div>
    <div class="user-avatar">
      <div *ngIf="role=='super' || role=='admin' || role=='workPlace' " class="sprite-calendar-img-avatar-header padding-right tooltip-info" (click)="clickGoToSchedules($event)">
           <img src="assets/custom/{{imagepath}}/icono/profile.svg" style="width:40px; height:40px" >
           <span class="tooltip-info-text">Panel</span>
      </div>
      <div class="sprite-calendar-img-avatar-header tooltip-info">
         <img src="assets/custom/{{imagepath}}/icono/logout.svg"  (click)="clickExit($event)">
         <span class="tooltip-info-text">Salir</span>
      </div>
    </div>
  </section>

  <section class="mobile calendar-header">
    <div style=" display: flex; flex-direction: row; align-items:center; justify-content:space-between">
       <div class="logo" (click)="clickGoMain($event)"> 
           <img class="logo-image" src="assets/custom/{{imagepath}}/icono/logo.svg"/>
       </div>
       <div class="header-rest">
         <h1 class="title">
           {{name}}
         </h1>
       </div>
      <div class="user-avatar">
        <div class="sprite-calendar-img-avatar-header" (click)="openAsideMenu($event)">
          <div class="mobile menu-container">
            <button class="button-menu" (click)="openAsideMenu($event)">
                <i style="background:url('assets/custom/{{imagepath}}/icono/icon-menu.svg'); background-repeat:no-repeat" class="button-menu-image">
                </i>
            </button>
          </div>
          <div id="backdrop" class="backdrop" style="display:none">
            <a href="#" id="close" style="display:none" class="closebtn " (click)="closeNav($event)">&times;
                <span>Menú</span>
            </a>
         </div>
        </div>
      </div>

      <div>
          <div id="sideNavigation"  class="aside mobile" style="display:none">
              <app-aside-gral-menu-mobile [name]='name' [calendars]='calendars'></app-aside-gral-menu-mobile>
          </div>
      </div>
    </div>
  </section>

  <body> 
   <div class="calendar-sidebar md-lg-mobile-desktop" id="divCalendarPicker" (click)="closeMenu()">
      <div class="sidebar-content">
        <app-calendar-picker (dateSelected)="clickDateSelected($event)" (changeDate)="ChangeDate($event)" [dateFullCalendar]="this.dateDefault" id="calendarPicker"></app-calendar-picker>
      </div>
    </div>
    <div class="content-calendar" id='calendar'>
        <div #myDatePicker *ngIf="isOpenCalendar" class="datePickerCustomButton">
          <app-calendar-picker (dateSelected)="clickDateSelected($event)" (changeDate)="ChangeDate($event)" [dateFullCalendar]="this.dateDefault" id="calendarPicker"></app-calendar-picker>
        </div>
    </div>
    <div #modal></div>
    <div #messages></div>
    <div #series></div>
    <div #options></div>
   <!---- <div id="divCalendarPickerMobile" style="display:none">
      <app-calendar-picker (dateSelected)="clickDateSelected($event)" (changeDate)="ChangeDate($event)" [dateFullCalendar]="this.dateDefault" id="calendarPickerMobile"></app-calendar-picker>
    </div>-->
    <nav *ngIf="mostrar" #myDOMElement class="menu" [ngStyle]="{'left':getPositionX(),'top':getPositionY(),'position':'absolute'}"> 
        <ul class="menu-list">
          <li>
            <div class="menu-1">
              <mat-icon class="icon-menu" svgIcon="move-to"></mat-icon>
              <a style="padding-top:5px">Mover a</a>
            </div>
            <ul [ngClass]="{'fixedX': minOffSetX  , 'noFixedX': !minOffSetX, 'fixedY': minOffSetY  , 'noFixedY': !minOffSetY }"> 
              <div class="datepicker">
                 <label>Mover a</label>
                   <div class="sidebar-content ">
                     <app-date-picker (dateSelected)="updateDateEvent($event)"></app-date-picker>
                  </div>
                </div>
            </ul>
          </li>
          <li>
            <div class="menu-2">
              <mat-icon class="icon-menu" svgIcon="copy-to"></mat-icon>
              <a style="padding-top:5px">Copiar a</a>
            </div>
            <ul [ngClass]="{'fixedX': minOffSetX  , 'noFixedX': !minOffSetX,'fixedY': minOffSetY  , 'noFixedY': !minOffSetY}">
              <div class="datepicker">
                <label>Copiar a</label>
                <div class="sidebar-content ">
                  <app-date-picker (dateSelected)="copyEvent($event)"></app-date-picker>
                </div>
              </div>
            </ul>
          </li>
          <li>
            <div class="menu-3">
              <mat-icon class="icon-menu" svgIcon="delete"></mat-icon>
              <a (click)="deleteEvent($event)" style="padding-top:5px">Eliminar</a>
            </div>
          </li>
          <li  *ngIf="(idTask==0 || !idTask || idTask<1) && !associate">
            <div class="menu-3">
              <!--<mat-icon class="icon-menu" svgIcon="delete"></mat-icon>-->
              <i class="fa-brands fa-whatsapp fa-lg" style="color: #63E6BE; position: relative; left:12px; top: 12px; margin-right:15px"></i>
              <a (click)="sendWhatsapp($event)" style="padding-top:5px">Whatsapp</a>
            </div>
          </li>
      </ul>
    </nav>
  </body>
</html>
