<div class="modal" >
    <div class="eventmessage" cdkDrag>
           <a *ngIf="action=='done' || action=='alert' || action=='serie'" (click)='closeEvent($event)' class="button ghost close-icon">
               <i class="sprite-event-close" style="background:url('assets/img/close-black.svg')"></i>
           </a>
           
            <div>
                <div class="actions-content">
                    <span *ngIf="action=='done'" class="actions-icons">
                        <i style="background: url('assets/img/icon-success.svg') no-repeat; width:18px; height:18px; display:flex">
                        </i>
                    </span>
                    <span *ngIf="action=='alert' || action=='serie'" class="actions-icons">
                        <i style="background: url('assets/img/icon-warning-red.svg') no-repeat; width:18px; height:18px; display:flex">
                        </i>
                    </span>
                    <span *ngIf="action=='delete'" class="actions-icons">
                        <i style="background: url('assets/img/icon-warning.svg') no-repeat; width:18px; height:18px; display:flex">
                        </i>
                    </span>
                   
                    <h2>{{title}}</h2>
                </div>
                <!--<h2>{{title}}</h2>-->
                <div class="message-delete">
                    {{message}} 
                </div>
            </div>
         
            <div  class="buttons">
                        <button *ngIf="action=='delete' || action=='update'"  (click)="action=='delete'? deleteEvent($event) : confirmEvent($event)"  type="submit" class="button button-accept">
                            <i class="sprite-event-check-white" style="
                                background:url('assets/img/check.svg')">
                            </i>
                             <span>Aceptar</span>
                           
                        </button>

                        <button *ngIf="action=='delete' || action=='' || action=='update'"  (click)="cancelEvent($event)"  type="submit" class="button button-cancel">
                             <i class="sprite-event-check-white" style="
                                background:url('assets/img/close.svg')">
                            </i>
                            <span>Cancelar</span>
                       </button> 
            </div>
     </div>
</div>
