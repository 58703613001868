import { Component, OnInit, Output,Input, EventEmitter, ViewEncapsulation} from '@angular/core';


@Component({
  selector: 'app-series-events',
  templateUrl: './series-events.component.html',
  styleUrls: ['./series-events.component.css'],
  //encapsulation: ViewEncapsulation.None
})

export class SeriesEventsComponent implements OnInit {
    @Output() seriesConfirmEvent = new EventEmitter();
    @Output() seriesCloseEvent = new EventEmitter();
    @Output() seriesCancelEvent = new EventEmitter();
    @Input() typeAction: any
    @Input() messageA: any
    @Input() messageB: any
    @Input() messageC:any
    
    constructor() { }

  ngOnInit(): void {

  }

  closeModal(){
    this.seriesCancelEvent.emit({result:'cancel'})
  }

  action(event, value){
    event.preventDefault();
    event.stopPropagation();
    switch (this.typeAction)
    {
      case 'delete-series':
        this.seriesConfirmEvent.emit({result:'delete-series', type: value})
        break;
      case 'update-series':
        this.seriesConfirmEvent.emit({result:'update-series', type: value})
        break;
    }

  }

  delete(event, value){
   event.preventDefault();
   event.stopPropagation();
   this.seriesConfirmEvent.emit({result:'delete-series', type: value})
  }
}