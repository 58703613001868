import { Credentials } from './credentials'

export enum ResultLoginStatus {
    NoLogged, Logged, Logging, Error, Logout
}

export class ResultLogin {
    status: ResultLoginStatus = ResultLoginStatus.NoLogged;
    credentials: Credentials;
}
