<div style="display:flex; flex-direction:row">
    <select *ngIf="groupSelect=='none'" class="form-control form-control-sm" [(ngModel)]='field1' (change)="setValue($event)">
        <option *ngFor="let item of arrOptions" [ngValue]="item">{{item.name}}</option>
    </select>
    <select  *ngIf="groupSelect=='service'"  class="form-control form-control-sm" [(ngModel)]='field1' (change)="setValue($event)">
        <optgroup *ngFor="let item of arrOptions"  label="{{item.name}}" class="optgroup-options">
                <option *ngFor="let subitem of item.services" [ngValue]="subitem" class="optgroup-options">{{subitem.name}}</option>
        </optgroup>
    </select>
    <button (click)="deleteOption()" type="submit" class="button calendar-delete">
        <span class="button-event">x</span>
   </button>
</div>


