import { Component, OnInit,Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-add-event-modal',
  templateUrl: './add-event-modal.component.html',
  styleUrls: ['./add-event-modal.component.css']
})
export class AddEventModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {name: string; arg:any}) { }

  ngOnInit(): void {
  }

}
