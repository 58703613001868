import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './access/login/login.component';
import { MainComponent } from './main/main.component';
import { ResourcesViewCalendarComponent } from './resources-view-calendar/resources-view-calendar.component';
import { PanelComponent } from './panel/panel.component';
import { WorkplaceCalendarsComponent } from './panel/workplace-calendars/workplace-calendars.component';




const routes: Routes = [ { path: '',redirectTo:'login', pathMatch: "full" },
{path:'login', component:LoginComponent, pathMatch: "full"},
{path:'login/:param', component:LoginComponent, pathMatch: "full"},
{path:'main', component: MainComponent, pathMatch: "full"},
{path:'resources/:id/:name',component:ResourcesViewCalendarComponent, pathMatch:"full"},
{path:'resources/:id/:name/:user',component:ResourcesViewCalendarComponent, pathMatch:"full"},
//{path:'panel/:id', component:PanelComponent, pathMatch:"full"},

//{path: 'panel/:id/:query',component: PanelComponent, pathMatch:"full"},
/*{path: 'panel/:id/:query', component: PanelComponent,
    children: [
        {
            path: 'workplaces',
            component: WorkplaceSchedulesComponent,
            }
      
      ]},*/
//{path: 'panel', component:PanelComponent, loadChildren:()=>PanelModule},
{path: 'panel/:id', loadChildren:()=>import('./panel/panel.module').then (x=>x.PanelModule) },
//{path: 'panel/:id', loadChildren:()=>import('./panel/panel.module').then (x=>x.PanelModule) },
//{path: 'panel/:id/:query', loadChildren:()=>import('./panel/panel.module').then (x=>x.PanelModule) },
{ path: '**', component: LoginComponent }];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})


export class AppRoutingModule { 
}
