<div class="modal" >

     <div class="eventeditor eventeditor-search">
             <a (click)='closeEvent($event)' class="button ghost close-icon">
                 <i class="sprite-event-close" style="background:url('assets/img/close-black.svg')"></i>
             </a>
             <div class="main-title">
                 <h2>Buscar cliente</h2>
             </div>
             <div class="search-customer">
                <div class="content-customer ">
                    <div class="form-group col-md-4 col-md-4-sm">
                        <label>Cliente</label>
                        <div class="flex-row">
                            <input class="form-control-customer" type="text"  [(ngModel)]="nameCustomer" name="search" autocomplete="off" placeholder="Buscar por nombre" (keyup.enter)="onKey(searchText)">
                        </div>
                    </div>
                    <div class="form-group col-md-4 col-md-4-sm">
                        <label>Matrícula</label>
                        <div class="flex-row">
                            <input class="form-control-customer" type="text"  [(ngModel)]="registration" name="search" autocomplete="off" placeholder="Buscar por matrícula" (keyup.enter)="onKey(searchText)">
                      
                        </div>
                    </div>
                    <div class="form-group col-md-4 col-md-4-sm">
                        <label></label>
                        <div class="flex-row">
                            <button class="button button-search desktop" (click)="searchCustomer($event)" type="submit">Buscar</button>
                            <a class="link clear"  href="#" *ngIf="nameCustomer || registration" (click)="clearCustomers($event)">
                                <i class="sprite-event-close" style="background: url('assets/img/icons8-cancelar.svg');"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-results">
                <div  class="header" >
               <!---- <div *ngIf="typeSearch=='customer'|| (customers.length==0 && criteria.field=='name')" class="header" >-->
                    <div class="row" style="width:100%">
                        <div class="cell cell-1 cell-1-header">Nombre</div>
                        <div class="cell cell-3 cell-1-header">Teléfono</div>
                        <div class="cell cell-2 cell-1-header">Matrícula</div>
                        <div class="cell cell-4 cell-4-header">Acciones</div>
                    </div>
                   
                </div>
               <!---- <div *ngIf="typeSearch=='vehicles'" class="header" >
                    <div class="row" style="width:100%">
                        <div class="cell cell-2 cell-1-header">Matrícula</div>
                        <div class="cell cell-4 cell-4-header">Acciones</div>
                    </div>
                   
                </div>-->
                <div class="body">
                    <div *ngIf="loading" style="display:flex; justify-content: center; align-items: center; width:inherit" >
                        <mat-spinner [diameter]="25"></mat-spinner>
                    </div>
                    <div *ngIf="customers.length==0 && !loading && !error && customersRegistrations.length==0" class="text-no-found">
                        <p>{{msg}}</p>
                    </div>
                  <!---- <div *ngIf="customers.length>0 && typeSearch=='customer' && customersRegistrations.length==0" style="width:100%">
                        <div *ngFor="let item of customers, let i=index" class="row" style="width:100%"  [ngClass]="{ 'resaltado': selectedDiv === i }" (click)="highlightDiv(i)">
                            <div class="cell cell-1 value" [ngClass]="{ 'resaltado': selectedDiv === i }">
                              {{item.NOMCLTE}} 
                            </div>
                            <div class="cell cell-3  value resaltado" [ngClass]="{ 'resaltado': selectedDiv === i }">
                                {{item.TELEFONO}}
                             </div>
                            <div class="cell cell-2 value resaltado" [ngClass]="{ 'resaltado': selectedDiv === i }">
                                {{item.REGISTRATION}}
                            </div>
                           <div *ngIf="selectedDiv === i " class="cell cell-4 cell-4-header desktop" style="justify-content:center" >
                                  
                                   <a class="link" (click)="selectCustomer($event,item)" >
                                        <i class="sprite-customer-check-white text-link" style="
                                            background:url('assets/img/check.svg')">
                                        </i>
                                        <span class="text-link" [ngClass]="{ 'resaltado': selectedDiv === i }">Agregar</span>  
                                    </a>
                                 
                            </div>
                      </div> 
                    </div>-->
    
                    <div *ngIf="customersRegistrations.length>0" style="width:100%">
                        <div *ngFor="let item of customersRegistrations, let i=index" class="row" style="width:100%"  [ngClass]="{ 'resaltado': selectedDiv === i }" (click)="highlightDiv(i)">
                            <div  class="cell cell-1 value" [ngClass]="{ 'resaltado': selectedDiv === i }">
                              {{item.NOMCLTE}} 
                            </div>
                            <div  class="cell cell-3  value resaltado" [ngClass]="{ 'resaltado': selectedDiv === i }">
                                {{item.TELEFONO}} 
                             </div>
                             <div class="cell cell-2 value resaltado" [ngClass]="{ 'resaltado': selectedDiv === i }">
                                {{item.REGISTRATION}} 
                            </div>
                           <!---- <div *ngIf="typeSearch=='vehicles'" class="cell cell-2 value resaltado" [ngClass]="{ 'resaltado': selectedDiv === i }">
                                {{item.MATRICULA}} aqui
                            </div>-->
                           <div *ngIf="selectedDiv === i " class="cell cell-4 cell-4-header desktop" style="justify-content:center" >
                                  
                                   <a class="link" (click)="selectCustomer($event,item)" >
                                        <i class="sprite-customer-check-white text-link" style="
                                            background:url('assets/custom/{{imagepath}}/icono/check.svg')">
                                        </i>
                                        <span class="text-link" [ngClass]="{ 'resaltado': selectedDiv === i }">Agregar</span>  
                                    </a>
                                 
                            </div>
                      </div> 
                    </div>
                
                <div *ngIf="error" class="message">
                    <p>{{msg}}</p>
                </div>
            </div>
     </div>
 </div>