import { OnDestroy,  Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
@Injectable()

export abstract class Subscriber implements OnDestroy {
    private subscriptions: Subscription[] = [];
    private indexedSubscriptions: { [key: string]: Subscription } = {};

    protected addSubscription(sub: Subscription, key: string = null) {
        this.subscriptions.push(sub);
        if (key) {
            this.indexedSubscriptions[key] = sub;
        }
    }
    protected addBatchSubscriptions(...subs: Array<Subscription>) {
        subs.forEach(sub => this.addSubscription(sub));
    }
    unsubscribe(subs: Subscription | string) {
        const subscription = subs instanceof Subscription ? subs : this.indexedSubscriptions[subs];
        if (subscription && subscription.closed) { subscription.unsubscribe(); }
    }
    cleanSubscriptions() {
        this.subscriptions.map(sub => (!sub || sub.closed) ? null : sub.unsubscribe());
    }
    ngOnDestroy() {
        this.cleanSubscriptions();
    }
}
