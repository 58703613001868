<!--<mat-form-field>
  <input [formControl]="search" matInput placeholder="Buscar">
  <mat-icon class="white-icon" matSuffix>search</mat-icon>
</mat-form-field> -->

<div class="box">
  <div class="container-1">
    <span class="icon"><i style="background:url('assets/img/icon-search-white.svg') no-repeat 0px 5px;"></i></span>
    <input id="Buscador" type="text" class="search" placeholder="Buscar por nombre" (keyup)="searchTerm$.next($event.target.value)">
  </div>
</div>