type repeatType= 'daily'|'weekly'|'monthly'|'annually'; 

export class Recurrence {
    repeat : boolean;
    type: string;
    repeatAlways: boolean;
    repeatUntil: Date;
    repeatEvery: number;
    repeatDuring: number;
    repeatWeeklyMonday: boolean;
    repeatWeeklyTuesday:boolean;
    repeatWeeklyWednesday:boolean;
    repeatWeeklyThursday:boolean;
    repeatWeeklyFriday:boolean;
    repeatWeeklySaturday:boolean;
    repeatWeeklySunday:boolean;
    repeatMonthDayPosition:number;
    repeatWeekday:number;
    repeatWeekdayPosition:number

    constructor(
        repeat=false,
        type?,
        repeatAlways?,
        repeatUntil?,
        repeatEvery?,
        repeatDuring?,
        repeatWeeklyMonday?,
        repeatWeeklyTuesday?,
        repeatWeeklyWednesday?,
        repeatWeeklyThursday?,
        repeatWeeklyFriday?,
        repeatWeeklySaturday?,
        repeatWeeklySunday?,
        repeatMonthDayPosition?,
        repeatWeekday?,
        repeatWeekdayPosition?




    ){
        this.repeat=repeat;
        this.type=type;
        this.repeatAlways=repeatAlways;
        this.repeatUntil=repeatUntil;
        this.repeatEvery= repeatEvery;
        this.repeatDuring= repeatDuring;
        this.repeatWeeklyMonday=  repeatWeeklyMonday;
        this.repeatWeeklyTuesday =repeatWeeklyTuesday;
        this.repeatWeeklyWednesday= repeatWeeklyWednesday;
        this.repeatWeeklyThursday= repeatWeeklyThursday;
        this.repeatWeeklyFriday= repeatWeeklyFriday;
        this.repeatWeeklySaturday= repeatWeeklySaturday;
        this.repeatWeeklySunday= repeatWeeklySunday;
        this.repeatMonthDayPosition=repeatMonthDayPosition;
        this.repeatWeekday=repeatWeekday,
        this.repeatWeekdayPosition=repeatWeekdayPosition

        
    }

  
   
}