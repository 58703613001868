
  <div class="desktop calendar-header">
      <div class="logo" (click)="clickGoMain($event)"> 
        <div>
          <img class="logo-image" src="assets/custom/{{imagepath}}/icono/logo.svg"/>
        </div>
        <div class="common-margins" >
          <span class="title-logo">1Cal</span>
        </div>
      </div>
      <div class="header-rest">
        <h1 class="title">
          {{title}}
        </h1>
      </div>
    <div *ngIf="search" class="search common-margins">
      <app-search (search)="handleSearch($event)"></app-search>
    </div>
     <div class="user-avatar">
      <div class="sprite-calendar-img-avatar-header">
           <img src="assets/custom/{{imagepath}}/icono/logout.svg" (click)="clickExit($event)">
        </div>
      </div>
  </div>
  <div class="mobile calendar-header">
     <div style=" display: flex; flex-direction: row; align-items:center">
        <div class="logo common-margins" (click)="clickGoMain($event)"> 
          <div>
            <img class="logo-image" src="assets/custom/{{imagepath}}/icono/logo.svg"/>
          </div>
          <!--<div class="common-margins">-->
          <div>
            <span class="title-logo">1Cal</span>
          </div>
        </div>
        <div class="header-rest">
          <h1 class="title">
            {{title}}
          </h1>
        </div>
       <div style="width:24px; height:24px" (click)="openAsideMenu($event)">
        <img class="menu-image" src="assets/img/icon-menu.svg"/>
      </div>
      <div id="backdrop" class="backdrop" style="display:none">
        <a href="#" id="close" style="display:none" class="closebtn " (click)="closeNav($event)">&times;
            <span>Menú</span>
        </a>
     </div>
    </div>
  </div>

  <div>
    <div id="sideNavigation"  class="aside mobile" style="display:none">
      <div id="menu-item">
        <div class="item">
            <a  href="#">
                <div class="img">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0,0,256,256" width="20px" height="20px" fill-rule="nonzero"><g fill="#ffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(10.66667,10.66667)"><path d="M12,2c-5.511,0 -10,4.489 -10,10c0,5.511 4.489,10 10,10c5.511,0 10,-4.489 10,-10c0,-5.511 -4.489,-10 -10,-10zM12,4c4.43012,0 8,3.56988 8,8c0,4.43012 -3.56988,8 -8,8c-4.43012,0 -8,-3.56988 -8,-8c0,-4.43012 3.56988,-8 8,-8zM11,6v6.41406l4.29297,4.29297l1.41406,-1.41406l-3.70703,-3.70703v-5.58594z"></path></g></g></svg>
                </div>
                <span class="title">Buscar</span>
            </a>
        </div>
       <div class="separator">
       </div>
       <div class="item">
        <a  href="#" >
            <div class="img">
                <svg width="20px" height="20px"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-top:3px">
                    <g id="Calendar / Calendar">
                    <path id="Vector" d="M4 8H20M4 8V16.8002C4 17.9203 4 18.4801 4.21799 18.9079C4.40973 19.2842 4.71547 19.5905 5.0918 19.7822C5.5192 20 6.07899 20 7.19691 20H16.8031C17.921 20 18.48 20 18.9074 19.7822C19.2837 19.5905 19.5905 19.2842 19.7822 18.9079C20 18.4805 20 17.9215 20 16.8036V8M4 8V7.2002C4 6.08009 4 5.51962 4.21799 5.0918C4.40973 4.71547 4.71547 4.40973 5.0918 4.21799C5.51962 4 6.08009 4 7.2002 4H8M20 8V7.19691C20 6.07899 20 5.5192 19.7822 5.0918C19.5905 4.71547 19.2837 4.40973 18.9074 4.21799C18.4796 4 17.9203 4 16.8002 4H16M16 2V4M16 4H8M8 2V4" stroke="#ffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                </svg>
            </div>
            <span class="title">Cerrar Sesión</span>
        </a>
        </div>
      </div>
    </div>
</div>



