<div class="modal">
    <div class="eventeditor">
        <a (click)='closeEvent($event)' class="button ghost close-icon">
            <i class="sprite-event-close" style="background:url('assets/img/close-black.svg')"></i>
        </a>
        <div id="title" class="title">
           <h2> Compartir agenda</h2>
        </div>
       <form [formGroup]="myForm" (ngSubmit)="sendEvent($event)" novalidate>
            <div class="group">
                <label for="phone" class="label-phone">Móvil</label>
                <div class="phone-detail">
                    <select class="code" formControlName="code" >
                        <option selected value ="+34">+34</option>
                    </select>
                    <input type="text" id="phone" formControlName="phone" [(ngModel)]="phone" pattern="^[0-8]+([0-9]+)?$" placeholder="Ingrese número de teléfono">
                </div>
            </div>
            <div class="group">
                <label for="message" class="label-phone">Mensaje</label>
               <!---- <input  type="text" class="message-text" formControlName="messageTxT"  id="message" cols="40" rows="5">--->
               <textarea readonly class="message-text"  id="message" cols="80" rows="5"></textarea>
            </div>
            <div class="buttons">
                <button type="submit" class="button save-button">
                    <i class="sprite-event-check-white" style="background:url('assets/img/check.svg')">
                     </i>
                      <span class="button-event">Enviar</span>
                 </button>
            </div>
        </form>
   
       <div *ngIf="error!=''" class="error">{{this.error}}</div>
    </div>
</div>