import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariables } from './local/globalVariables.service';

@Injectable ()

export class ConfigService {

   configuracion: any={};
   hostname: any
    constructor(private http: HttpClient, private globalVariables:GlobalVariables) { 
        this.http = http;
        //console.log("servicio corriendo")
        this.hostname = document.location.hostname.replace(/\.com|\.es|\.net|\.it|\.de|\.fr|\.pt|\.us|\.co.uk|\.uk|\.org/g,'').replace(/\./g,'-')
        http.get("../../assets/json/config.json").subscribe(res=>{this.configuracion=res})
    }
    loadAppConfig() {
        return this.http.get('/assets/json/config.json')
         .toPromise()
         .then(res => {
            this.configuracion = res;
          
         });
        }

  
}