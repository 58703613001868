import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

 /* transform(lista: any[], texto:string): any[] {
    if(!texto) return lista

    return lista.filter(workplace=>workplace.name.toUpperCase().includes(texto.toUpperCase()))
    
  }*/
  transform(value:any, arg:any): any {
    const result=[];
    if(!arg) return value
     
    for (const item of value)
     {
       if(item.name.toLowerCase().indexOf(arg.toLowerCase())>-1)
         result.push(item)

     }

     return result;


    
  }
  

}
