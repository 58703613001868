import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user';
import { ResultLoginStatus } from '../../models/resultLogin';
import { ServiceStatus } from '../../shared/models/serviceStatus';
import { Credentials } from '../../models/credentials';
import { ResultLogin } from '../../models/resultLogin';
import { HttpClient } from '@angular/common/http';
import { GlobalVariables } from 'src/app/services/local/globalVariables.service';
import { Subscription } from 'rxjs';

import { Base64, cifrarXor } from '../../shared/helpers/utils';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  email: string;
  password: string;
  data: any;
  user: User;
  token: string;

  cred = new Credentials({});
  resultLoginStatus = ResultLoginStatus;
  resultLogin: ResultLogin = new ResultLogin();
  serviceStatus = ServiceStatus;
  loginStatus = ServiceStatus.Ini;
  imagepath: string='';
  userNameLoggedSubs: Subscription;
  userNameLogged: any

  encryptSecretKey = "JA#AOO3O2G$8P2LK";


  constructor(
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    private userService: UserService,
    public activatedRouter: ActivatedRoute,
    private http: HttpClient,
    private globalVariables: GlobalVariables
  ) {

    this.auth.resultLogin.subscribe(
      res => {
        if (res) {
          this.resultLogin = res;
          //ERROR
          if (!res || res.status == ResultLoginStatus.Error) {
            this.loginStatus = ServiceStatus.Error;
            return;
          }

          //lOGIN
          if (res.status == ResultLoginStatus.Logged) {
            this.loginStatus = ServiceStatus.Ok
            this.auth.userLogin.subscribe(user=>{
              this.user=user
              this.globalVariables.setUserLogged(this.user)
              this.globalVariables.setUserNameLogged(this.user.userName)
              this.globalVariables.setRol(this.user.role)
                }
               )
            this.router.navigate(['/'])
          }

          //LOGOUT
          if (res.status == ResultLoginStatus.Logout) {
            this.loginStatus = ServiceStatus.Ini;
            this.router.navigate(['/login']);
            return;
          }
        }
      }
    )
  }

  ngOnInit(): void {

   // this.imagepath=localStorage.getItem("urlimage")
    this.imagepath=this.globalVariables.getUrlImage()


    if (this.activatedRouter.snapshot.params.param) {
      this.cred = JSON.parse(cifrarXor(Base64.decode(decodeURI(this.activatedRouter.snapshot.params.param)), this.encryptSecretKey));
      this.login(this.cred);
      return;
    }

    if (localStorage.getItem("saveCred")) {
      this.cred = JSON.parse(cifrarXor(Base64.decode(decodeURIComponent(localStorage.getItem("saveCred"))), this.encryptSecretKey));
    }
   
  }

  login(cred: Credentials) {
    this.loginStatus = ServiceStatus.Loading;
    this.auth.login(cred);
    this.auth.resultLogin.subscribe(
      res => {
        if (res) {
          if (res.status == this.resultLoginStatus.Logged) {
            this.loginStatus = ServiceStatus.Ok
            this.auth.userLogin.subscribe(user=>{
              this.user=user
              this.globalVariables.setUserLogged(this.user)
              this.globalVariables.setRol(this.user.role)
              localStorage.setItem('role',this.user.role)
                }
               )
            localStorage.setItem("saveCred", encodeURIComponent(Base64.encode(cifrarXor(JSON.stringify(cred), this.encryptSecretKey))));
            localStorage.setItem("userName", this.user.userName)
          
            this.router.navigate(['main'])
          }
          else {
            if (res.status == this.resultLoginStatus.Error) {
              this.loginStatus = ServiceStatus.Error
              this.router.navigate(['login'])
            }
          }
        }
      }
    )
  }
}