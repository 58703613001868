<div id="sidemenu" class="mostrando">

    <ul>
        <div  class="logo"> 
          <img class="logo-image" src="assets/custom/{{imagepath}}/icono/logo.svg"/>
        </div>
        <li>
            <div>
                <a href="#" (click)="clickGoMain($event)">
                    <div class="aside-item">
                        <svg   width="20px" height="20px" fill="#FFFF" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.87 110.25" style="enable-background:new 0 0 122.87 110.25" xml:space="preserve"><g><path class="st0" d="M11.51,101.98V58.89c-2.34,0.9-4.53,0.92-6.35,0.3c-1.42-0.48-2.62-1.34-3.5-2.45 c-0.88-1.11-1.44-2.46-1.61-3.95c-0.26-2.31,0.43-4.92,2.4-7.37l0,0c0.1-0.12,0.21-0.24,0.34-0.34L59.85,0.55 c0.74-0.68,1.88-0.75,2.7-0.11l57.18,44.45l0,0c0.09,0.07,0.17,0.14,0.25,0.23c2.65,2.85,3.31,6.01,2.67,8.68 c-0.32,1.32-0.95,2.5-1.82,3.48c-0.87,0.98-1.98,1.74-3.24,2.19c-2,0.72-4.38,0.7-6.79-0.44v42.94h8v8.27H4.08v-8.27H11.51 L11.51,101.98z M105.2,101.98v-45.4c0-1.01-39.23-32.02-43.55-35.39c-4.59,3.49-44.53,34.25-44.53,35.55v45.24H105.2L105.2,101.98z M62.9,53.06c-1.31-0.51-2.67-0.78-4.07-0.78c-2.1,0-3.83,0.67-5.19,2.01l-11.71,11.7l1.22,1.23l-4.44,4.47l-7.48-7.49l4.44-4.44 l1.38,1.35l11.64-11.63c1.6-1.59,3.32-2.38,5.16-2.38C56.94,47.09,59.95,49.08,62.9,53.06L62.9,53.06L62.9,53.06z M80.66,49.75 c1.27,0,2.49,0.22,3.63,0.61l-2.89,3.13c-6.56,7.1,0.84,11.21,7.37,6.33l2.52-1.88c0.23,0.89,0.36,1.83,0.36,2.79 c0,6.07-4.92,10.99-10.99,10.99c-1.98,0-3.84-0.52-5.44-1.44l-5.34,5.67c5.02,4.81,10.05,9.62,15.07,14.42l-6.82,6.82 c-4.75-4.96-9.5-9.91-14.25-14.87l-9.36,9.93c-0.71,1.21-2.03,2.03-3.54,2.03c-2.26,0-4.1-1.83-4.1-4.1c0-0.98,0.34-1.88,0.92-2.58 l-0.01,0l0.04-0.03c0.3-0.36,0.65-0.66,1.05-0.9l10.52-9.02L46.44,64.05l5.38-5.38c5,4.8,9.05,8.65,14.05,13.44l5.83-5 c-1.28-1.8-2.03-4-2.03-6.37C69.67,54.67,74.59,49.75,80.66,49.75L80.66,49.75L80.66,49.75z M44.79,51.67l-5.35,5.35l-2.13-2.13 l5.35-5.35L44.79,51.67L44.79,51.67z"/></g></svg>
                        <span>Centros</span>
                    </div>
                </a>
            </div>
        </li>
        <li>
            <div>
                <a href="#" (click)="clickGoToSchedules($event)">
                    <div class="aside-item">
                        <svg width="20px" height="20px"  fill="#FFFF" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 45.532 45.532" xml:space="preserve" stroke="#FFFF"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M22.766,0.001C10.194,0.001,0,10.193,0,22.766s10.193,22.765,22.766,22.765c12.574,0,22.766-10.192,22.766-22.765 S35.34,0.001,22.766,0.001z M22.766,6.808c4.16,0,7.531,3.372,7.531,7.53c0,4.159-3.371,7.53-7.531,7.53 c-4.158,0-7.529-3.371-7.529-7.53C15.237,10.18,18.608,6.808,22.766,6.808z M22.761,39.579c-4.149,0-7.949-1.511-10.88-4.012 c-0.714-0.609-1.126-1.502-1.126-2.439c0-4.217,3.413-7.592,7.631-7.592h8.762c4.219,0,7.619,3.375,7.619,7.592 c0,0.938-0.41,1.829-1.125,2.438C30.712,38.068,26.911,39.579,22.761,39.579z"></path> </g> </g>
                        </svg>
                        <span>Panel</span>
                    </div>
                </a>
            </div>
        </li>
       <!----<li id='separator'>
            <div class="separator"></div>
        </li>-->
        <li>
            <div>
                <a href="#" (click)="clickExit($event)">
                    <div class="aside-item">
                        <svg width="20px" height="20px"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22"><path fill="#ffff" d="M14.08,15.59L16.67,13H7V11H16.67L14.08,8.41L15.5,7L20.5,12L15.5,17L14.08,15.59M19,3A2,2 0 0,1 21,5V9.67L19,7.67V5H5V19H19V16.33L21,14.33V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H19Z" /></svg>
                        <span>Cerrar Sesión</span>
                    </div>
                </a>
            </div>
        </li>
    </ul>
</div>