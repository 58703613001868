<div class="modal">
    <div class="eventeditor-series" cdkDrag>
        <div class="modal__window_series"> 
            <h1>Opciones de Evento Periódico</h1>
            <p class="text">Existen múltiples eventos en esta serie. ¿Qué eventos deseas cambiar?</p>
        </div>
        <div class="recurrence-edit-modes">
            <a class="recurrence-edit-mode" href="#" (click)="action($event,'onlyThisEvent')">
               <div class="img-series">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" style="width: 60px; height: 60px; padding-left: 10px">
                    <g fill="none" fill-rule="evenodd">
                    <circle cx="7" cy="33" r="3" fill="#22BA8E" id="id_101"></circle>
                    <circle cx="20" cy="33" r="3" fill="#22BA8E" id="id_102"></circle>
                    <circle cx="33" cy="33" r="3" fill="#0a6c80" id="id_103"></circle>
                    <circle cx="46" cy="33" r="3" fill="#22BA8E" id="id_104"></circle>
                    <circle cx="59" cy="33" r="3" fill="#22BA8E" id="id_105"></circle>
                    </g>
                </svg>
                <span class="text">Único</span>
               </div> 
                <div class="text">
                    <p>{{messageA}}</p>
                    <!--<p> "Aplicar únicamente a este evento."</p>-->
                </div>  
            </a>
            <a class="recurrence-edit-mode" href="#" (click)="action($event,'allEvents')">
                <div class="img-series">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" style="width: 60px; height: 60px; padding-left: 10px">
                    <g fill="none" fill-rule="evenodd">
                    <circle cx="7" cy="33" r="3" fill="#046b80" id="id_106"></circle>
                    <circle cx="20" cy="33" r="3" fill="#036b80" id="id_107"></circle>
                    <circle cx="33" cy="33" r="3" fill="#086c80" id="id_108"></circle>
                    <circle cx="46" cy="33" r="3" fill="#056c80" id="id_109"></circle>
                    <circle cx="59" cy="33" r="3" fill="#036b80" id="id_110"></circle>
                    </g>
                    </svg>
                    <span class="text">Todos</span>
                </div>
                <div class="text">
                    <p>{{messageB}}</p>
                   <!----<p> "Aplicar a todos los eventos de la serie."</p>-->
                </div>
            </a>
            <a class="recurrence-edit-mode" href="#" (click)="action($event,'thisEventAndLater')">
                <div class="img-series">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" style="width: 60px; height: 60px; padding-left: 10px">
                    <g fill="none" fill-rule="evenodd">
                    <circle cx="7" cy="33" r="3" fill="#22BA8E" id="id_106"></circle>
                    <circle cx="20" cy="33" r="3" fill="#22BA8E" id="id_107"></circle>
                    <circle cx="33" cy="33" r="3" fill="#086c80" id="id_108"></circle>
                    <circle cx="46" cy="33" r="3" fill="#056c80" id="id_109"></circle>
                    <circle cx="59" cy="33" r="3" fill="#036b80" id="id_110"></circle>
                    </g>
                    </svg>
                    <span class="text">Siguientes</span>
                </div>
               <div class="text">
                <p>{{messageC}}</p>
               <!----<p> "Aplicar a este evento y a los siguientes."</p>-->
               </div>
            </a>

        </div>
        <p class="confirm-options">
            <button class="button button-cancel" (click)="closeModal()">
                <i class="sprite-event-check-white" style="
                 background:url('assets/img/close.svg')">
                </i>
                <span class="button-event">Cancelar</span>
           </button>
        <p>
    </div>
   
   
</div> 